<div class="personal-information">
  <form
    class="contact-form"
    [formGroup]="sourceFormGroup"
    (ngSubmit)="onSave()"
  >
    <h1 class="title">Proba si tu cliente califica</h1>
    <div class="fields">
      <input
        class="input first-input col-md-12"
        type="text"
        formControlName="name"
        placeholder="Nombre del cliente"
        maxlength="50"
        autocomplete="off"
        [ngClass]="{
          'field-error':
            (sourceFormGroup.get('name').touched || submitted) &&
            sourceFormGroup.get('name').invalid
        }"
      />
      <div
        class="error"
        *ngIf="
          (sourceFormGroup.get('name').touched || submitted) &&
          sourceFormGroup.get('name').invalid
        "
      >
        <div
          class="invalid-text"
          *ngIf="sourceFormGroup.get('name').hasError('required')"
        >
          Ingrese nombre del cliente por favor.
        </div>
        <div
          class="invalid-text"
          *ngIf="!sourceFormGroup.get('name').hasError('required')"
        >
          Ingrese un nombre válido por favor.
        </div>
      </div>
      <input
        class="input col-md-12"
        type="tel"
        formControlName="id"
        placeholder="DNI del cliente"
        maxlength="8"
        autocomplete="off"
        [ngClass]="{
          'field-error':
            (sourceFormGroup.get('id').touched || submitted) &&
            sourceFormGroup.get('id').invalid
        }"
      />
      <div
        class="error"
        *ngIf="
          (sourceFormGroup.get('id').touched || submitted) &&
          sourceFormGroup.get('id').invalid
        "
      >
        <div
          class="invalid-text"
          *ngIf="sourceFormGroup.get('id').hasError('required')"
        >
          Ingrese el DNI del cliente por favor.
        </div>
        <div
          class="invalid-text"
          *ngIf="!sourceFormGroup.get('id').hasError('required')"
        >
          Ingrese un DNI válido por favor.
        </div>
      </div>
      <span class="out-selector">
        <span
          [ngClass]="{
            arrow: !(
              (sourceFormGroup.controls['gender'].touched || submitted) &&
              sourceFormGroup.controls['gender'].invalid
            ),
            'arrow-error':
              (sourceFormGroup.controls['gender'].touched || submitted) &&
              sourceFormGroup.controls['gender'].invalid
          }"
        >
          <select
            class="input col-md-12 gender"
            formControlName="gender"
            [ngClass]="{
              'field-error':
                (sourceFormGroup.get('gender').touched || submitted) &&
                sourceFormGroup.get('gender').invalid
            }"
          >
            <option class="hide-option" [ngValue]="null"
              >Sexo del cliente</option
            >
            <option class="option" value="F" style="width:10px"
              >Femenino</option
            >
            <option class="option" value="M" style="width:10px"
              >Masculino</option
            >
          </select>
          <div
            class="error"
            *ngIf="
              (sourceFormGroup.get('gender').touched || submitted) &&
              sourceFormGroup.get('gender').invalid
            "
          >
            <div
              class="invalid-text"
              *ngIf="sourceFormGroup.get('gender').hasError('required')"
            >
              Seleccione el género del cliente por favor.
            </div>
          </div>
        </span>
      </span>
      <form
        [formGroup]="sourceFormGroup.get('userContact')"
        style="display: flex;flex-direction: column;"
      >
        <input
          class="input col-md-12 phone"
          type="tel"
          formControlName="phone"
          placeholder="Celular del cliente"
          maxlength="16"
          autocomplete="off"
          [ngClass]="{
            'field-error':
              (sourceFormGroup.get('userContact').get('phone').touched ||
                submitted) &&
              sourceFormGroup.get('userContact').get('phone').invalid
          }"
        />
        <div
          class="error"
          *ngIf="
            (sourceFormGroup.get('userContact').get('phone').touched ||
              submitted) &&
            sourceFormGroup.get('userContact').get('phone').invalid
          "
        >
          <div class="invalid-text">
            Ingrese el celular del cliente por favor.
          </div>
        </div>
        <input
          class="input col-md-12 agency"
          type="text"
          formControlName="agency"
          placeholder="Agencia"
          maxlength="90"
          [ngClass]="{
            'field-error':
              (sourceFormGroup.get('userContact').get('agency').touched ||
                submitted) &&
              sourceFormGroup.get('userContact').get('agency').invalid
          }"
        />
        <div
          class="error"
          *ngIf="
            (sourceFormGroup.get('userContact').get('agency').touched ||
              submitted) &&
            sourceFormGroup.get('userContact').get('agency').invalid
          "
        >
          <div
            class="invalid-text"
            *ngIf="
              sourceFormGroup
                .get('userContact')
                .get('agency')
                .hasError('required')
            "
          >
            Ingrese una agencia válida por favor.
          </div>
        </div>
      </form>
      <a
        class="link-whatsapp"
        [attr.href]="href"
        target="_blank"
        class="link-whatsapp"
      >
        <img
          class="image"
          src="../../../assets/icons/logo-whatsapp.png"
          height="50"
          width="144"
        />
      </a>
    </div>
    <button class="submit" type="submit">
      <div *ngIf="!sending">Enviar</div>
      <div *ngIf="sending">
        <spinner [scrollWidth]="7" [scrollHeight]="7"></spinner>
      </div>
    </button>
  </form>
</div>

<stepper-buttons [validSteps]="[sourceArrayForm.controls[0].valid , sourceArrayForm.controls[1].valid , false]"
  (step)="selectStep($event)" (flag)="false"></stepper-buttons>
<div class="container">
  <div class="carousel vert slide" data-interval="false" data-keyboard="false" data-wrap="false" data-ride="false">
    <div class="carousel-inner" [ngSwitch]="activeStep">
      <div class="carousel-item simulate active">
        <simulate-your-credit *ngSwitchCase="0" [sourceFormGroup]="sourceArrayForm.controls[0]"></simulate-your-credit>
      </div>
      <div class="carousel-item">
        <personal-information *ngSwitchCase="1" [sourceFormGroup]="sourceArrayForm.controls[1]"></personal-information>
      </div>
      <div class="carousel-item">
        <finalize *ngSwitchCase="2" [user]="sourceArrayForm.controls[1]"
          [result]="sourceArrayForm.controls[1].get('result').value"></finalize>
      </div>
    </div>
  </div>
</div>

<div class="benefits">
  <div class="information">
    <div class="title">
      Beneficios
    </div>
    <ul>
      <li>Obtenes tu préstamo en solo 3 pasos y salís a comprar el auto que te gusta.</li>
      <li>Sin costos de intermediación.</li>
      <li>Asesoramiento propio de gestoría.</li>
      <li>Aseguramos que toda la documentación de tu futura unidad sea válida y esté completa para la trasferencia.</li>
      <li>Elegís la unidad en la agencia y en la localidad que más te convenga.</li>
      <li>Trato directo con tu empresa de créditos.</li>
      <li>Pagas tu cuota en toda la red de Pago Fácil solo con tu DNI.</li>
      <li>También podes pagar a través de débito automático, transferencia bancaria o depósito bancario.</li>
      <li>Soporte y asesoramiento sobre el seguro del auto.</li>
    </ul>
  </div>
</div>

import { Component, OnDestroy } from '@angular/core';
import { SwUpdate } from '@angular/service-worker'
import { SubscriptionLike as ISubscription } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnDestroy {

  private subs: ISubscription[] = [];

  constructor(updates: SwUpdate) {
    this.subs.push(
      updates.available.subscribe(event => {
        updates.activateUpdate().then(() => document.location.reload());
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

import { Component, OnInit, Input } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { StepperButtonsService } from "../../../shared/stepper-buttons/stepper-buttons.service";
import { SimulatorViewService } from "../simulator-view.service";

@Component({
  selector: "finalize",
  templateUrl: "./finalize.component.html",
  styleUrls: ["./finalize.component.scss"]
})
export class FinalizeComponent implements OnInit {
  @Input() user: FormGroup;
  @Input() result: string;
  public loading: boolean;

  public state: number;

  constructor(
    private _stepperButtons: StepperButtonsService,
    private _simulatorView: SimulatorViewService
  ) {
    this.loading = false;
  }

  ngOnInit() {
    if (this.result === "N") {
      this.state = 4;
    } else {
      this.state = 0;
    }
  }
  public continue(type) {
    this.state = null;
    this.loading = true;
    if (type === 5) {
      this.loading = false;
      this._simulatorView.restartForm(true);
      this._stepperButtons.sendActiveStep(0);
    } else {
      let data = {
        dni: this.user.get("id").value,
        gender: this.user.get("gender").value,
        status: type
      };
      this._simulatorView.continue(data).subscribe(
        value => {
          this.loading = false;
          switch (type) {
            case 1:
              this.state = type;
              break;
            case 2:
              this.state = type;
              break;
            case 3:
              this.state = type;
              break;
            case 4:
              this.state = 3;
              break;
          }
        },
        err => {
          console.log(err);
          this.loading = false;
        }
      );
    }
  }

  public onSubmit() {
    this.loading = false;
    this._simulatorView.restartForm(true);
    this._stepperButtons.sendActiveStep(0);
  }
}

import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { SubscriptionLike as ISubscription } from 'rxjs';
import { OpenMenuService } from './open-menu.service';

declare var $ :any;

@Component({
  selector: 'main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

  @ViewChild('menuButton', {static: false}) menuButton: ElementRef;
  public menuOpen: boolean;
  public secondView: boolean;
  public activeView: string;
  public existBackButton: boolean;
  private subs: ISubscription[] = [];
  
  constructor(private _openMenuService: OpenMenuService) {
    this.menuOpen = false;
    this.secondView = false;
    this.existBackButton = false;
    this.activeView = "";
   }

  ngOnInit() {   
    let self = this;   
    this.subs.push( 
      this._openMenuService.getMenuActiveTab().subscribe((tab) =>{
        if(tab.activeTab !== null && tab.activeTab !== ''){
          self.nextScreen(tab.activeView, false);
          let el: HTMLElement = this.menuButton.nativeElement as HTMLElement;
          el.click();
        }
      })
    );
  }

  public nextScreen(activeView: string, existBackButton: boolean){
    this.activeView = activeView;   
    this.secondView = true;
    $('.carousel.horizontal').carousel('next');
    if (existBackButton){
      this.existBackButton = true;
    }
  }

  public previousScreen(){
    $('.carousel.horizontal').carousel('prev');
    this.secondView = false;
  }

  public destroyModal(){
      $('.carousel.horizontal').carousel('prev');
      this.activeView = "";
      this.secondView = false;
      this.menuOpen = false;
      this._openMenuService.setBlurredBackground(false);
      this.existBackButton = false;
      $('.modal').modal('hide')
  }

  public openMenu(){
    this.menuOpen = !this.menuOpen;
    this._openMenuService.setBlurredBackground(true);
  } 

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

}

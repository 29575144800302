import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { GlobalConstants } from "./app.constants";
import { delay } from "rxjs/operators";

@Injectable()
export class AppService {
    HTTP_OPTIONS = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Access-Control-Allow-Credentials' : 'true',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
        'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
      })
    };
  constructor(public constants: GlobalConstants, private _http: HttpClient) {}

  public httpGet(
    url: string,
    mocked: boolean = false,
    obj: any = null
  ): Observable<any> {
    if (!mocked) {
      const urlendpoint = this.constants.API_ENDPOINT + url;
      const headers = new HttpHeaders({
        "Content-Type": "application/json"
      });
      const options = {
        headers
      };
      return this._http.get<any>(urlendpoint);
    } else {
      return of(obj).pipe(delay(2000));
    }
  }

  public httpPost(url: string): Observable<any> {
    const urlendpoint = this.constants.API_ENDPOINT + url;
    return this._http.post<any>(urlendpoint, null);
  }

  public httpPostData(url: string, obj: any): Observable<any> {
    // const options = {
    //   headers: new HttpHeaders({
    //     "Content-Type": "application/json"
    //   })
    // };

    const urlendpoint = this.constants.API_ENDPOINT + url;
    return this._http.post<any>(urlendpoint, obj, this.HTTP_OPTIONS);
  }
}

import { Injectable } from "@angular/core";
import { AppService } from "../../app.service";
import { Contact } from "../../../models/contact.model";
import { FormGroup, FormArray } from "@angular/forms";
import { Simulation } from "../../../models/simulation.model";
import { CreditRequest } from "../../../models/credit-request";
import { Observable, Subject } from "rxjs";
import { CreditOptions } from "../../../models/credit-options";
import { getEmailFormatted, getPhoneFormatted } from "../../validators";
import { HttpParams } from "@angular/common/http";

@Injectable()
export class SimulatorViewService {
  private subject = new Subject<boolean>();

  constructor(private appService: AppService) {}

  public saveCreditRequest(data: any) {
    const obj: CreditRequest = {
      vehicleYear: data[0].carModel,
      borrowFees: data[0].feesAmount,
      borrowFeePrice:
        data[0].feePriceH.toFixed(2) + " - " + data[0].feePriceD.toFixed(2),
      borrowMoney: data[0].loanValue,
      name: data[1].name.trim(),
      id: data[1].id.toString(),
      gender: data[1].gender,
      email: String(data[1].userContact.email)
        .trim()
        .toLowerCase(),
      phone: String(data[1].userContact.phone)
        .trim()
        .toLowerCase(),
      agency: String(data[1].userContact.agency)
        .trim()
        .toLowerCase(),
      result: data[0].result,
      origin: 1
    };

    return this.appService.httpPostData(
      this.appService.constants.apiUrl.saveCreditRequest,
      obj
    );
  }

  public getAverageFee(form: FormGroup) {
    let value = String(form.get("loanValue").value).replace(/\./g, "");
    const obj: Simulation = {
      borrowAmount: Number(value),
      vehicleYear: form.get("carModel").value,
      borrowFees: 12 //form.get("feesAmount").value
    };
    const url = `${
      this.appService.constants.apiUrl.getAverageFee
    }/${obj.borrowFees.toString()}/${obj.vehicleYear.toString()}/${obj.borrowAmount.toString()}`;
    return this.appService.httpPost(url);
  }

  public getCreditOptions() {
    const url = this.appService.constants.apiUrl.getCreditOptions;
    return this.appService.httpGet(url);
  }

  public continue(data: any) {
    return this.appService.httpPostData(
      this.appService.constants.apiUrl.continue,
      data
    );
  }

  restartForm(restartForm: boolean) {
    this.subject.next(restartForm);
  }

  clearRestartForm() {
    this.subject.next();
  }

  getRestartForm(): Observable<any> {
    return this.subject.asObservable();
  }
}

<div class="term-conditions">
  <div class="information">
    <div class="title">
      Términos y condiciones
    </div>
    <strong>INTRODUCCIÓN, LA ACEPTACIÓN DE LAS CONDICIONES DEL SERVICIO.</strong>
    <p class="first-paragraph">
      Bienvenido y gracias por su interés en SBM Créditos. SBM Créditos S.A. (“SBM”) opera la aplicación a la que Ud.
      ha ingresado destinada a simular un crédito prendario (“App”).

      Los usuarios de la App son llamados de manera individual &quot;Usuario&quot; y colectivamente, los
      &quot;Usuarios&quot;.
    </p>
    <p>
      POR FAVOR LEA ATENTAMENTE LOS SIGUIENTES TÉRMINOS Y CONDICIONES DE SERVICIO. AL REGISTRARSE, ACCEDER, NAVEGAR O
      UTILIZAR LA APP, USTED RECONOCE QUE HA LEÍDO, ENTENDIDO, ACEPTADO Y SE COMPROMETE A CUMPLIR CON LOS SIGUIENTES
      TERMINOS Y CONDICIONES GENERALES Y PARTICULARES (COLECTIVAMENTE, LAS &quot;CONDICIONES&quot;) Y EXPRESA LA
      ACEPTACIÓN PLENA Y SIN RESERVAS DE TODAS Y CADA UNA DE LAS CONDICIONES EN LA VERSIÓN PUBLICADA POR SBM EN EL
      MOMENTO MISMO EN QUE EL USUARIO ACCEDA A LA APP. EN CONSECUENCIA, EL USUARIO DEBE LEER ATENTAMENTE ESTAS
      CONDICIONES EN CADA UNA DE LAS OCASIONES EN QUE SE PROPONGA UTILIZAR LA APP. CUALQUIER USUARIO QUE NO ACEPTE
      ESTAS CONDICIONES, LAS CUALES TIENEN UN CARÁCTER OBLIGATORIO Y VINCULANTE, DEBERÁ ABSTENERSE DE UTILIZAR LA APP.
    </p>
    <p>
      Se deja expresa constancia que SBM sólo pone a disposición de los Usuarios un medio virtual o plataforma que les
      posibilitará el cálculo estimativo de la cuota de un crédito hipotético. El efectivo otorgamiento del préstamo
      está sujeto al previo cumplimiento de requisitos comerciales y legales de SBM vinculados con requisitos
      personales del tomador del crédito como así a las características del automotor a prendar. En consecuencia, la
      simulación efectuada no resulta vinculante para SBM, quien podría denegar la operación; no configurando un
      supuesto de oferta conforme a la previsión del artículo 974 del Código Civil y Comercial. Los impuestos
      nacionales y provinciales están a cargo del cliente.
    </p>
    <p>
      • Toda la información publicada vinculada con los productos y servicios que ofrece SBM podrá ser modificada sin
      previo aviso. • Es condición esencial del servicio que SBM pone a su disposición, que la información contenida en
      la App sea utilizada en forma lícita y de acuerdo a los presentes términos y condiciones. • SBM no será
      responsable de los daños o perjuicios que el usuario pudiera sufrir derivados del acceso, uso o mala utilización
      de la App. • SBM no será responsable si por cualquier causa fuera imposible acceder a la App y/o se desconectara
      el servicio. • SBM prohíbe la copia, duplicación, redistribución, comercialización o cualquier otra actividad que
      se pueda realizar con o vinculada a los contenidos de la App, aún citando las fuentes, salvo consentimiento
      expreso.
    </p>
    <strong>PROPIEDAD INTELECTUAL.</strong>

    <p>
      La App es propiedad y está operado por SBM. Las interfaces visuales, gráficos, textos, logos, diseño,
      compilación, información, base de datos, código informático (incluido el código fuente o del código objeto), los
      productos, contenidos, software, servicios y todos los demás elementos de esta App proporcionados por SBM están
      protegidos por derecho de autor, patentes o las marcas comerciales, según corresponda. El Usuario reconoce que
      todos los materiales contenidos en la App y la App son propiedad de SBM.
    </p>
    <p>
      Con excepción de lo expresamente autorizado por SBM, el Usuario se compromete a no vender, licenciar, distribuir,
      copiar, modificar, reproducir o mostrar públicamente, transmitir, publicar, editar, adaptar, crear trabajos
      derivados de, o hacer un uso no autorizado de la App y sus contenidos. SBM se reserva todos los derechos que no
      fueran expresamente concedidos en las presentes Condiciones.
    </p>
    <strong>Protección de datos personales</strong>
    <p>
      La Ley de Protección de los Datos Personales (Ley N° 25.326) es una norma de orden público que regula la
      actividad de las bases de datos que registran información de carácter personal. Su objeto es garantizar a las
      personas el control del uso de sus datos personales.
    </p>
    <p>
      El titular de los datos personales tiene la facultad de ejercer los derechos de información, acceso,
      rectificación, actualización o supresión de dichos datos, en forma gratuita, conforme lo establecido en los
      artículos 13 a 20 de la Ley Nº 25.326. La DIRECCION NACIONAL DE PROTECCION DE DATOS PERSONALES, Órgano de Control
      de la Ley Nº 25.326, tiene la atribución de atender las denuncias y reclamos que se interpongan con relación al
      incumplimiento de las normas sobre protección de datos personales.
    </p>
    <p>
      SBM CRÉDITOS S.A. posee el carácter de Responsable Registrado ya que ha cumplimentado con todos los requisitos de
      licitud que exige la ley mencionada.
    </p>
    <strong>Ley aplicable y jurisdicción</strong>
    <p>
      Estas Condiciones se rigen por las leyes de la República Argentina. SBM y el Usuario, con renuncia expresa a
      cualquier otro fuero, se someten a la jurisdicción de los Tribunales Nacionales en lo Comercial de la Ciudad
      Autónoma de Buenos Aires (República Argentina) para cualquier controversia que pudiera derivarse de la prestación
      de los servicios objeto de estas Condiciones Generales.
    </p>
    <p>
      <strong>Sbm Créditos S.A.</strong>
    </p>
  </div>
</div>

<div class="iosinstallation-popover">
  <img class="icon" src="../../../assets/images/logo.png">
  <div class="text">
    <div class="first-line">
      <div class="sentence">
        Instalá ésta app presionando primero en
      </div>
      <img class="share" src="../../../assets/images/share.png">
    </div>
    <div class="second-line">
      y luego en "Agregar a la Pantalla de Inicio".
    </div>
  </div>
  <div class="close-button" (click)="closePopover()">
    <div class="close-icon fa fa-times"></div>
  </div>
</div>

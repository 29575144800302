import { Component, OnInit, OnDestroy } from "@angular/core";
import { FormArray, FormGroup, Validators, FormControl } from "@angular/forms";
import { noEmptyField, validPhoneOrEmail, validId } from "../../validators";
import { SubscriptionLike as ISubscription } from "rxjs";
import { SimulatorViewService } from "./simulator-view.service";
import { CreditOptions } from "../../../models/credit-options";
import { OpenMenuService } from "../../shared/main-menu/open-menu.service";
import { WhatsappContact } from "../../../models/whatsapp-contact.model";
import { ContactUsViewService } from "../contact-us-view/contact-us-view.service";

@Component({
  selector: "simulator-view",
  templateUrl: "./simulator-view.component.html",
  styleUrls: ["./simulator-view.component.scss"]
})
export class SimulatorViewComponent implements OnInit, OnDestroy {
  public simulatorStepper: FormArray;

  private feesAmountOptions = ["12", "18", "24", "36", "48"];
  private carModelOptions = ["2003-2008", "2009-2015", "2016-2018"];
  private creditValues = [30000, 150000];
  public loadedValues: boolean = false;
  private subs: ISubscription[] = [];
  public blurred: boolean;
  public IOSBanner: boolean;
  public whatsAppContact: WhatsappContact = {
    message: "",
    phone: ""
  };

  constructor(
    private _simulatorView: SimulatorViewService,
    private _openMenuService: OpenMenuService,
    private _contactUsService: ContactUsViewService
  ) {
    this.blurred = false;
    this.IOSBanner = false;
  }

  ngOnInit() {
    const self = this;
    self.showIOSBanner();
    self.subs.push(
      self._simulatorView.getCreditOptions().subscribe(
        (options: CreditOptions) => {
          if (options && !!options) {
            self.feesAmountOptions = options.feesAmountOptions;
            self.carModelOptions = options.vehicleYearOptions;
            self.creditValues = options.creditValues;
            self.whatsAppContact.phone = options.whatsAppPhone;
            self.whatsAppContact.message = options.whatsAppMessage;
            self._contactUsService.setWhatsAppContact(self.whatsAppContact);
          }
          self.initForms();
          self.loadedValues = true;
        },
        error => {
          console.log(error);
          self.initForms();
          self.loadedValues = true;
        }
      ),
      self._simulatorView.getRestartForm().subscribe((restart: boolean) => {
        if (restart) {
          self.initForms();
        }
      }),
      self._openMenuService.getBlurredBackground().subscribe(blurred => {
        self.blurred = blurred;
      })
    );
  }

  private initForms() {
    let localValue = null;
    localValue = new FormArray([
      new FormGroup({
        loanValue: new FormControl(null),
        creditValues: new FormControl(this.creditValues),
        feesAmountOptions: new FormControl(this.feesAmountOptions),
        carModelOptions: new FormControl(this.carModelOptions),
        feePriceD: new FormControl(null, { validators: [Validators.required] }),
        feePriceH: new FormControl(null, { validators: [Validators.required] }),
        feesAmount: new FormControl(null, {
          validators: [Validators.required]
        }),
        carModel: new FormControl(null, { validators: [Validators.required] })
      }),
      new FormGroup({
        name: new FormControl("", {
          validators: [Validators.required, noEmptyField]
        }),
        id: new FormControl("", { validators: [Validators.required, validId] }),
        gender: new FormControl(null, { validators: [Validators.required] }),
        result: new FormControl(null),
        userContact: new FormGroup(
          {
            phone: new FormControl(""),
            email: new FormControl(""),
            agency: new FormControl("")
          },
          {
            validators: [validPhoneOrEmail]
          }
        )
      })
    ]);
    this.simulatorStepper = localValue;
  }

  private showIOSBanner() {
    // Detects if device is on iOS
    const isIos = () => {
      const userAgent = window.navigator.userAgent.toLowerCase();
      return /iphone|ipad|ipod/.test(userAgent);
    };
    // Detects if device is in standalone mode
    var standaloneProperty: any = window.navigator;
    const isInStandaloneMode = () =>
      "standalone" in window.navigator && standaloneProperty.standalone;

    // Checks if should display install popup notification:
    if (isIos() && !isInStandaloneMode()) {
      this.IOSBanner = true;
    }
  }

  public closeiOSInstallationPopover(close) {
    this.IOSBanner = close;
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

<div class="simulate-credit">
  <form class="simulate-form" [formGroup]="sourceFormGroup" (ngSubmit)="nextStep()">
    <div class="container-img" [ngClass]="showCreditTypeQuestion===1?'slim':''">
      <img class="credit-image" src="../../../assets/images/bg.png" />
      <div class="title-bg">
        <img class="credit-logo" src="../../../assets/images/logo-lg.png" />
        <div class="lema" *ngIf="showCreditTypeQuestion===0||showCreditTypeQuestion===2">
          <strong>
            Pre aprobación<br />
            <span>inmediata</span><br />
          </strong>
        </div>
        <div class="logo" *ngIf="showCreditTypeQuestion===0||showCreditTypeQuestion===2">
          <p>Comercializa</p> 
          <img class="bco-logo" src="../../../assets/images/punto_prendario.png" alt="Punto Prendario" width="200">
        </div>
      </div>
    </div>
    <div *ngIf="showCreditTypeQuestion===0">
      <div class="question-content">
        <p class="question-label">¿Qué tipo de crédito desea consultar?</p>
        <div class="question-options">
          <button class="question-option btn-primary" (click)="pushPrendario()">Prendarios</button>
          <button class="question-option btn-primary" (click)="pushPersonal()">Personales</button>
        </div>
      </div>
    </div>
    <div class="content-body prendario" *ngIf="showCreditTypeQuestion===1">
      <stepper-buttons [validSteps]="[false , false , false]" (step)="0" flag="true">
      </stepper-buttons>
      <div class="form-container">
        <div class="select-value with-prefix">
          <span class="input-group-addon" *ngIf="showPrefix"
            [ngClass]="{'field-error': (sourceFormGroup.controls['loanValue'].touched || submitted) && sourceFormGroup.controls['loanValue'].invalid}">$</span>
          <input #loanValue formControlName="loanValue" type="tel" name="loanValue" id="loanValue" autocomplete="off"
            required class="input col-md-12 car-value" (input)="setMoneyValue($event.target.value)"
            (click)="focusLoanValue()" placeholder="¿Monto del préstamo?"
            [ngClass]="{'field-error': (sourceFormGroup.controls['loanValue'].touched || submitted) && sourceFormGroup.controls['loanValue'].invalid}">
        </div>
        <div class="error"
          *ngIf="(sourceFormGroup.controls['loanValue'].touched || submitted) && sourceFormGroup.controls['loanValue'].invalid">
          <div class="invalid-text"
            *ngIf="sourceFormGroup.controls['loanValue'].hasError('required') || sourceFormGroup.controls['loanValue'].hasError('incorrect')">
            {{placeholderValue}}
          </div>
        </div>
        <span class="out-selector">
          <span
            [ngClass]="{'arrow':!((sourceFormGroup.controls['carModel'].touched || submitted) && sourceFormGroup.controls['carModel'].invalid),
                            'arrow-error':(sourceFormGroup.controls['carModel'].touched || submitted) && sourceFormGroup.controls['carModel'].invalid}">
            <select class="input col-md-12 car-model" formControlName="carModel"
              [ngClass]="{'field-error': (sourceFormGroup.controls['carModel'].touched || submitted) && sourceFormGroup.controls['carModel'].invalid}">
              <option class="hide-option" [value]="null">Año del auto</option>
              <option class="option" *ngFor="let model of sourceFormGroup.controls['carModelOptions'].value"
                [value]="model.substr(5,4)" style="width:10px">{{model}}</option>
            </select>
            <div class="error"
              *ngIf="(sourceFormGroup.controls['carModel'].touched || submitted) && sourceFormGroup.controls['carModel'].invalid">
              <div class="invalid-text" *ngIf="sourceFormGroup.controls['carModel'].hasError('required')">
                Seleccione el año del automotor por favor.
              </div>
            </div>
          </span>
        </span>
        <div class="fee-display col-md-12">
          <div *ngIf="!showTable" class="fee-field">
            <img class="image-car" src="../../../../assets/images/car.png">
            <div class="message">
              Cuota mensual
            </div>
          </div>
          <div *ngIf="gettingFeePrice" class="loading-field">
            <spinner [scrollWidth]="7" [scrollHeight]="7"></spinner>
          </div>
          <div class="table-result" *ngIf="showTable">
            <div class="table">
              <div class="table-header">
                <div class="header__item">Plazo</div>
                <div class="header__item">cuota UVA <br/><span>desde</span></div>
                <div class="header__item">cuota Fija<br/><span>desde</span></div>
              </div>
              <div class="table-content">	
                <div class="table-row">		
                  <div class="table-data">12</div>
                  <div class="table-data">$ 58.120</div>
                  <div class="table-data">$ 117.850</div>
                </div>
                <div class="table-row">
                  <div class="table-data">18</div>
                  <div class="table-data">$ 52.600</div>
                  <div class="table-data">$ 111.480</div>
                </div>
                <div class="table-row">
                  <div class="table-data">24</div>
                  <div class="table-data">$ 45.890</div>
                  <div class="table-data">$ 101.950</div>
                </div>
                <div class="table-row">
                  <div class="table-data">30</div>
                  <div class="table-data">$ 41.890</div>
                  <div class="table-data">$ 95.600</div>
                </div>
                <div class="table-row">
                  <div class="table-data">36</div>
                  <div class="table-data">$ 37.200</div>
                  <div class="table-data">$ 91.550</div>
                </div>
                <div class="table-row">
                  <div class="table-data">48</div>
                  <div class="table-data">$ 31.440</div>
                  <div class="table-data">$ 87.400</div>
                </div>
              </div>	
            </div>
          </div>
          <!-- <div *ngIf="!gettingFeePrice && sourceFormGroup.get('feePriceD').value !== null" class="fee-field">

            <div class="p1">
              <img class="image-car" src="../../../../assets/images/car.png">
              Cuotas:
            </div>
            <div class="p2">
              <div class="message">
                desde
                <div class="price">
                  {{feePriceD}}
                </div>
              </div>
              <div class="message">
                hasta
                <div class="price">
                  {{feePriceH}}
                </div>
              </div>
            </div>
          </div> -->
        </div>
        <div class="legal-notice-small">
          <span>*</span> La cuota no incluye seguro del auto.
        </div>
        <div class="menu-links">
          <ul class="links">
            <li>
              <div class='link' (click)="openMenuOption('formalities')">
                Requisitos
              </div>
            </li>
            <li class="space">
              <div class="link" (click)="openMenuOption('benefits')">
                Beneficios
              </div>
            </li>
          </ul>
          <a class="link-whatsapp" [attr.href]="href" target="_blank">
            <img class="image" src="../../../assets/icons/logo-whatsapp.png" height="50" width="144" />
          </a>
        </div>
        <div class="legal-notice">
          El otorgamiento del préstamo está sujeto al previo cumplimiento de requisitos comerciales y legales de SBM.
          La
          presente SIMULACIÓN no resulta vinculante para la entidad, quien podría denegar la operación; NO
          CONSTITUYENDO
          UNA OFERTA conforme a la previsión del articulo 974 del Código Civil y Comercial. El valor de las cuotas es
          estimativo. Los impuestos nacionales y provinciales están a cargo del cliente.
        </div>
        <div class="legal-notice">
          La utilización de esta aplicación implica la aceptación de sus <span class="link"
            (click)="openMenuOption('termConditions')">términos
            y condiciones</span>.
        </div>
      </div>
      <button class="submit" type="submit">
        <div>Continuar</div>
      </button>
      </div>
      <div class="content-body personal" *ngIf="showCreditTypeQuestion===2">
        <p class="message">Lo siento, pero esta sección aún no está lista para su uso.</p>
        <p class="message">Estamos trabajando en ello.</p>

        <button class="submit"(click)="back()">Volver</button>
      </div>
  </form>
</div>

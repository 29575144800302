<div class="contact-us">
  <form class="contact-form" [formGroup]="contactForm" (ngSubmit)="onSubmit()">
    <div class="title">
      Contacto
    </div>
    <div class="fields">
      <input class="input first-input col-md-12" type="text" formControlName="firstName" placeholder="Nombre"
        maxlength="50" autocomplete="off"
        [ngClass]="{'field-error': (contactForm.get('firstName').touched || submitted) && contactForm.get('firstName').invalid}">
      <div class="error"
        *ngIf="(contactForm.get('firstName').touched || submitted) && contactForm.get('firstName').invalid">
        <div class="invalid-text" *ngIf="contactForm.get('firstName').hasError('required')">
          Ingrese su nombre por favor.
        </div>
        <div class="invalid-text" *ngIf="!contactForm.get('firstName').hasError('required')">
          Ingrese un nombre válido por favor.
        </div>
      </div>
      <input class="input col-md-12" type="text" formControlName="lastName" placeholder="Apellido" maxlength="50"
        autocomplete="off"
        [ngClass]="{'field-error': (contactForm.get('lastName').touched || submitted) && contactForm.get('lastName').invalid}">
      <div class="error"
        *ngIf="(contactForm.get('lastName').touched || submitted) && contactForm.get('lastName').invalid">
        <div class="invalid-text" *ngIf="contactForm.get('lastName').hasError('required')">
          Ingrese su apellido por favor.
        </div>
        <div class="invalid-text" *ngIf="!contactForm.get('lastName').hasError('required')">
          Ingrese un apellido válido por favor.
        </div>
      </div>
      <form [formGroup]="contactForm.get('userContact')" style="display: flex;flex-direction: column;">
        <input class="input  col-md-12" type="text" formControlName="agency" placeholder="Agencia" maxlength="50"
          autocomplete="off"
          [ngClass]="{'field-error': (contactForm.get('userContact').get('agency').touched || submitted) && contactForm.get('userContact').get('agency').invalid}">
        <div class="error"
          *ngIf="(contactForm.get('userContact').get('agency').touched || submitted) && contactForm.get('userContact').get('agency').invalid">
          <div class="invalid-text" *ngIf="contactForm.get('userContact').get('agency').hasError('required')">
            Ingrese su nombre de agencia por favor.
          </div>
          <div class="invalid-text" *ngIf="!contactForm.get('userContact').get('agency').hasError('required')">
            Ingrese un nombre de agencia válido por favor.
          </div>
        </div>
        <input class="input col-md-12 email" type="email" formControlName="email" placeholder="Correo Electrónico"
          maxlength="50" autocomplete="off" [ngClass]="{'field-error': (contactForm.get('userContact').get('email').touched || submitted) && 
                                           contactForm.get('userContact').get('email').invalid}">
        <div class="error" *ngIf="(contactForm.get('userContact').get('email').touched || submitted) && 
                                      contactForm.get('userContact').get('email').invalid">
          <div class="invalid-text">
            Ingrese un correo electrónico válido por favor.
          </div>
        </div>
        <input class="input col-md-12" type="tel" formControlName="phone" placeholder="Celular" maxlength="16"
          autocomplete="off"
          [ngClass]="{'field-error': (contactForm.get('userContact').get('phone').touched || submitted) && contactForm.get('userContact').get('phone').invalid}">
        <div class="error"
          *ngIf="(contactForm.get('userContact').get('phone').touched || submitted) && contactForm.get('userContact').get('phone').invalid">
          <div class="invalid-text">
            Ingrese un celular válido por favor.
          </div>
        </div>
      </form>
      <textarea class="input text-area col-md-12" formControlName="message" placeholder="Mensaje" maxlength="4000"
        autocomplete="off"
        [ngClass]="{'field-error': (contactForm.get('message').touched || submitted) && contactForm.get('message').invalid}"></textarea>
      <div class="error"
        *ngIf="(contactForm.get('message').touched || submitted) && contactForm.get('message').invalid">
        <div class="invalid-text" *ngIf="contactForm.get('message').hasError('required')">
          Ingrese un mensaje por favor.
        </div>
        <div class="invalid-text" *ngIf="!contactForm.get('message').hasError('required')">
          Ingrese un mensaje válido por favor.
        </div>
      </div>
      <a class="link-whatsapp" [attr.href]="href" target="_blank" class="link-whatsapp">
        <img class="image" src="../../../assets/icons/logo-whatsapp.png" height="50" width="144" />
      </a>
    </div>
    <button class="submit" type="submit">
      <div *ngIf="!sending">Enviar</div>
      <div *ngIf="sending">
        <div>
          <spinner [scrollWidth]="7" [scrollHeight]="7"></spinner>
        </div>
      </div>
    </button>
  </form>
</div>

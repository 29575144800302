<div class="stepper-buttons" [ngClass]="{'hide': selectedStep === 0 && !flag}">
  <div class="stepper">
    <div class="step">
      <div class="label-circle" (click)="stepsEnabled[0] && selectStep(0)"
        [ngStyle]="{'cursor': stepsEnabled[0] ? 'pointer' : 'default'}">
        <div class="circle" [ngClass]="{'active-circle': selectedStep === 0}">
          <div *ngIf="!validSteps[0]" class="element">1</div>
          <div *ngIf="validSteps[0]" class="element fa fa-check"></div>
        </div>
        <div class="label" [ngClass]="{'active-label': selectedStep === 0}">Cotizá</div>
      </div>
      <div class="divider"></div>
    </div>
    <div class="step">
      <div class="label-circle" (click)="stepsEnabled[1] && selectStep(1)"
        [ngStyle]="{'cursor': stepsEnabled[1] ? 'pointer' : 'default'}">
        <div class="circle" [ngClass]="{'active-circle': selectedStep === 1}">
          <div *ngIf="!validSteps[1]" class="element">2</div>
          <div *ngIf="validSteps[1]" class="element fa fa-check"></div>
        </div>
        <div class="label" [ngClass]="{'active-label': selectedStep === 1}">Calificá</div>
      </div>
      <div class="divider"></div>
    </div>
    <div class="step">
      <div class="label-circle" (click)="stepsEnabled[2] && selectStep(2)"
        [ngStyle]="{'cursor': stepsEnabled[2]  ? 'pointer' : 'default'}">
        <div class="circle" [ngClass]="{'active-circle': selectedStep === 2}">
          <div class="element">3</div>
        </div>
        <div class="label" [ngClass]="{'active-label': selectedStep === 2}">Vende</div>
      </div>
    </div>
  </div>
</div>

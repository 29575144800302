<div class="finalize">
  <div class="finalize-body">

    <div class="loader" *ngIf="loading">
      <div class="lds-ring">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <span>Tu consulta puede tardar unos segundos...</span>
    </div>

    <div class="aprobado msg" *ngIf="state === 0">
      <h2>¡Felicitaciones!</h2>
      <p>El crédito ha sido Pre-aprobado.</p>
      <div class="pregunta">
        <p>¿Deseas continuar con el proceso?</p>
        <div class="action">
          <button id="aNo" class="btn btn-secondary" (click)="continue(5)">No</button>
          <button id="aSi" class="btn btn-primary" (click)="continue(3)">Si</button>
        </div>
      </div>
    </div>

    <div class="continuar1 msg" *ngIf="state === 1">
      <h2>¡Entendido!</h2>
      <p>¿Cuentanos porque no quieres seguir con el proceso?</p>
      <div class="pregunta">
        <button id="cp1" class="btn btn-secondary" (click)="continue(5)">Necesito financiar el 100% del valor del
          auto</button>
        <button id="cp2" class="btn btn-secondary" (click)="continue(5)">Solo quería saber si califico</button>
        <button id="cp3" class="btn btn-secondary" (click)="continue(5)">Quería un crédito personal</button>
        <button id="cp4" class="btn btn-secondary" (click)="continue(2)">Todavía no busque el auto que quiero
          comprar</button>
      </div>
    </div>

    <div class="continuar2 msg" *ngIf="state === 2">
      <h2>¿Queres que te ayudemos a buscar tu vehículo?</h2>
      <div class="pregunta">
        <div class="action">
          <button id="cNo" class="btn btn-secondary" (click)="continue(5)">No</button>
          <button id="cSi" class="btn btn-primary" (click)="continue(4)">Si</button>
        </div>
      </div>
    </div>

    <div class="continuar3 msg" *ngIf="state === 3">
      <h2>¡Excelente!</h2>
      <p>Un agente te contactará en la brevedad.</p>
    </div>

    <div class="desaprobado msg" *ngIf="state === 4">
      <p>Lo sentimos el crédito</p>
      <h2>No ha sido aprobado</h2>
    </div>
  </div>
  <button class="submit" (click)="onSubmit()" *ngIf="state === 4 || state === 3">
    <div *ngIf="!sending">Cerrar</div>
    <div *ngIf="sending">
      <div>
        <spinner [scrollWidth]="7" [scrollHeight]="7"></spinner>
      </div>
    </div>
  </button>
</div>

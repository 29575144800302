import { Component, OnInit, Input } from "@angular/core";
import { FormGroup, FormArray } from "@angular/forms";
import { SubscriptionLike as ISubscription } from "rxjs";
import { StepperButtonsService } from "../../../shared/stepper-buttons/stepper-buttons.service";
import { SimulatorViewService } from "../simulator-view.service";

import { LocalStorageService } from "../../../app.local-storage.service";

import { ContactUsViewService } from "../../contact-us-view/contact-us-view.service";
import { WhatsappContact } from "../../../../models/whatsapp-contact.model";

@Component({
  selector: "personal-information",
  templateUrl: "./personal-information.component.html",
  styleUrls: ["./personal-information.component.scss"]
})
export class PersonalInformationComponent implements OnInit {
  @Input() sourceFormGroup: FormGroup;
  private subs: ISubscription[] = [];
  public sending: boolean;
  public submitted: boolean;
  private whatsAppContact: WhatsappContact;
  public href: string;

  constructor(
    private _stepperButtons: StepperButtonsService,
    private _simulatorViewService: SimulatorViewService,
    private _localStorageService: LocalStorageService,
    private contactUsViewService: ContactUsViewService
  ) {
    this.submitted = false;
    this.sending = false;
  }

  ngOnInit() {
    this._localStorageService.getAgency().subscribe(
      value => {
        this.sourceFormGroup
          .get("userContact")
          .get("agency")
          .setValue(value);
      },
      err => {
        console.log(err);
      }
    );

    this.whatsAppContact = this.contactUsViewService.getWhatsAppContact();
    this.href = `https://api.whatsapp.com/send?phone=${
      this.whatsAppContact.phone
    }&text=${encodeURIComponent(this.whatsAppContact.message)}`;
  }

  onSave() {
    this.submitted = true;
    this._localStorageService
      .setAgency(this.sourceFormGroup.get("userContact").get("agency").value)
      .subscribe();
    if (this.sourceFormGroup.valid) {
      if (!this.sending) {
        this.sending = true;
        this._simulatorViewService
          .saveCreditRequest(this.sourceFormGroup.root.value)
          .subscribe(
            value => {
              this.sending = false;
              this.sourceFormGroup.get("result").setValue(value.resultado);
              this._stepperButtons.sendActiveStep(2);
            },
            err => {
              console.log(err);
              this.sending = false;
            }
          );
      }
    }
  }
}

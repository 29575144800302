<div class="about-us">
  <div class="information">
    <div class="title">
      Somos
    </div>
    <p class="first-paragraph">
      Somos la empresa líder en el mercado de créditos prendarios desde el año 2003.
    </p>
    <p>
      Hacemos fácil el otorgamiento de créditos prendarios para la compra de vehículos 0 km y usados.
    </p>
    <p>
      Nos destacamos por ofrecer respuestas flexibles e inmediatas para ayudarte a obtener tu préstamo prendario.
    </p>
    <p>
      Brindamos asesoramiento integral para que tu crédito prendario se ajuste a tu bolsillo y no pagues de mas.
    </p>
    <p>
      Contamos con el asesoramiento financiero, de gestoría y de seguros para que tu crédito prendarios sea el que más se ajuste
      a tus necesidades.
    </p>
  </div>
</div>
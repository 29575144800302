<button class="menu-button" type="button" aria-label="Menu button">
  <img #menuButton id="openModalButton" *ngIf="!menuOpen" class="menu-icon" src="../../../assets/icons/menu-icon-sm.png"
    data-toggle="modal" data-target=".bd-example-modal-lg" (click)="openMenu()">
</button>
<div *ngIf="menuOpen" class="blurred-box"></div>
<div class="modal fade bd-example-modal-lg" data-backdrop="false" data-keyboard="false" tabindex="-1" role="dialog"
  aria-labelledby="Menu" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <nav class="modal-header">
        <button *ngIf="secondView && existBackButton" class="previous-button" type="button" (click)="previousScreen()">
          <div class="angle-icon fa fa-chevron-left animated fade-in"></div>
        </button>
        <button class="quit-button" type="button" (click)="destroyModal()">
          <div class="close-icon fa fa-times"></div>
        </button>
      </nav>
      <div class="carousel horizontal slide" data-interval="false" data-keyboard="false" data-wrap="false"
        data-ride="false">
        <div class="carousel-inner">
          <div class="carousel-item active">
            <div class="menu-panel">
              <div class="titles">
                <div class="title" (click)="nextScreen('aboutUs', true)">Somos</div>
                <div class="title" (click)="nextScreen('formalities', true)">Requisitos</div>
                <div class="title" (click)="nextScreen('benefits', true)">Beneficios</div>
                <div class="title" (click)="nextScreen('contactUs', true)">Contacto</div>
                <div class="title tc" (click)="nextScreen('termConditions', true)">Términos y condiciones</div>
              </div>
            </div>
          </div>
          <div class="carousel-item">
            <div class="active-view" [ngSwitch]="activeView">
              <about-us-view *ngSwitchCase="'aboutUs'"></about-us-view>
              <formalities-view *ngSwitchCase="'formalities'"></formalities-view>
              <benefits-view *ngSwitchCase="'benefits'"></benefits-view>
              <contact-us-view *ngSwitchCase="'contactUs'"></contact-us-view>
              <term-conditions-view *ngSwitchCase="'termConditions'"></term-conditions-view>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

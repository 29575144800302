import {
  Component,
  OnInit,
  EventEmitter,
  Output,
  OnDestroy,
  Input,
  SimpleChanges,
  OnChanges
} from "@angular/core";
import { StepperButtonsService } from "./stepper-buttons.service";
import { SubscriptionLike as ISubscription } from "rxjs";

@Component({
  selector: "stepper-buttons",
  templateUrl: "./stepper-buttons.component.html",
  styleUrls: ["./stepper-buttons.component.scss"]
})
export class StepperButtonsComponent implements OnInit, OnChanges, OnDestroy {
  @Output() step = new EventEmitter<number>();
  @Input() flag: Boolean;
  @Input() validSteps: boolean[];

  public selectedStep: number;
  private subs: ISubscription[] = [];
  public stepsEnabled: boolean[];

  constructor(private _stepperButtonsService: StepperButtonsService) {
    this.stepsEnabled = [false, false, false];
  }

  ngOnInit() {
    this.selectedStep = 0;
    this.subs.push(
      this._stepperButtonsService.getActiveStep().subscribe(val => {
        this.selectStep(val.activeStep);
      })
    );
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes.firstChange) {
      if (changes.validSteps.previousValue) {
        this.stepsEnabled = this.getEnabledSteps(
          changes.validSteps.currentValue
        );
      }
    }
  }

  private getEnabledSteps(validSteps): boolean[] {
    let currentSteps = [false, false, false];
    if (!validSteps[0] && !validSteps[1]) {
      currentSteps = [true, false, false];
    } else if (validSteps[0] && !validSteps[1]) {
      currentSteps = [true, true, false];
    } else if (!validSteps[0] && validSteps[1]) {
      currentSteps = [true, false, false];
    } else if (validSteps[0] && validSteps[1]) {
      currentSteps = [true, true, false];
    }
    return currentSteps;
  }

  public selectStep(step: number) {
    this.selectedStep = step;
    this.step.emit(step);
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }
}

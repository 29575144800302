<div class="formalities">
  <div class="information">
    <div class="title">
      Requisitos
    </div>
    <ul>
      <li>Personas físicas entre 18 y 70 años de edad.</li>
      <li>Jubilados y/o pensionados.</li>
      <li>Buen comportamiento crediticio.</li>
      <li>Argentino y/o extranjero con DNI y residencia permanente.</li>
      <li>Antigüedad laboral mínima 6 meses.</li>
      <li>Unidades 0km a 15 años de antigüedad.</li>
      <li>Monto mínimo $ 10.000.</li>
      <li>Monto máximo $ 300.000.</li>
      <li>Plazo mínimo 6 meses. Plazo máximo 48 meses.</li>
      <li>Automóviles y utilitarios livianos.</li>
    </ul>
  </div>
</div>
